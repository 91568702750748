// THESE FILES ARE HERE ONLY AS REFERENCE AND ARE NOT BEING USED FOR ANYTHING
// THEY'RE REFERENCE FOR `main-head.ejs` script tag called `fedopsLoadStarted`
// WHICH IS COMPILED TO ES AND MINIFIED VERSION OF THIS FILE.
import { isBot, sendBeacon, isSuspectedBot, isIFrame, extractCachingData, uuidv4 } from '@wix/thunderbolt-environment'

//	eslint-disable-next-line
;(function () {
	const { site, rollout, fleetConfig, requestUrl, isInSEO, shouldReportErrorOnlyInPanorama } = window.fedops.data
	const btype = isBot(window) || isIFrame() || isSuspectedBot() || (isInSEO ? 'seo' : '')
	const ish = !!btype
	const { isCached, caching, microPop } = extractCachingData(
		document.cookie,
		() => performance.getEntriesByType('navigation')[0].serverTiming || []
	)
	const types = {
		WixSite: 1,
		UGC: 2,
		Template: 3,
	}
	const st = types[site.siteType] || 0
	const fedOpsAppName = site.appNameForBiEvents
	const { isDACRollout, siteAssetsVersionsRollout } = rollout
	const is_dac_rollout = isDACRollout ? 1 : 0
	const is_sav_rollout = siteAssetsVersionsRollout ? 1 : 0
	const is_rollout = fleetConfig.code === 0 || fleetConfig.code === 1 ? fleetConfig.code : null
	const is_company_network = fleetConfig.code === 2 ? true : false
	const ts = Date.now() - window.initialTimestamps.initialTimestamp
	const tsn = Math.round(performance.now()) // client only
	const { visibilityState } = document
	const pageVisibilty = visibilityState
	const { fedops, addEventListener, thunderboltVersion } = window
	fedops.apps = fedops.apps || {}
	fedops.apps[fedOpsAppName] = { startLoadTime: tsn }
	fedops.sessionId = site.sessionId
	fedops.vsi = uuidv4()
	fedops.is_cached = isCached
	fedops.phaseStarted = reportPhase(28)
	fedops.phaseEnded = reportPhase(22)

	performance.mark('[cache] ' + caching + (microPop ? ' [' + microPop + ']' : ''))

	fedops.reportError = (error: any, errorType: string = 'load') => {
		const info = error?.reason || error?.message
		if (info) {
			if (!shouldReportErrorOnlyInPanorama) {
				sendBI(26, `&errorInfo=${info}&errorType=${errorType}`)
			}
			sendPanorama({
				error: {
					name: errorType,
					message: info,
					stack: error?.stack!,
				},
			})
		} else {
			error.preventDefault()
		}
	}
	addEventListener('error', fedops.reportError)
	addEventListener('unhandledrejection', fedops.reportError)

	let bfcache = false
	addEventListener(
		'pageshow',
		({ persisted }) => {
			if (persisted) {
				if (!bfcache) {
					bfcache = true
					fedops.is_cached = true
				}
			}
		},
		true
	)

	function sendBI(evid: number, extra = '') {
		if (requestUrl.includes('suppressbi=true')) {
			return
		}
		const url =
			'//frog.wix.com/bolt-performance?src=72&evid=' +
			evid +
			'&appName=' +
			fedOpsAppName +
			'&is_rollout=' +
			is_rollout +
			'&is_company_network=' +
			is_company_network +
			'&is_sav_rollout=' +
			is_sav_rollout +
			'&is_dac_rollout=' +
			is_dac_rollout +
			'&dc=' +
			site.dc +
			(microPop ? '&microPop=' + microPop : '') +
			'&is_cached=' +
			isCached +
			'&msid=' +
			site.metaSiteId +
			'&session_id=' +
			window.fedops.sessionId +
			'&ish=' +
			ish +
			'&isb=' +
			ish +
			(ish ? '&isbr=' + btype : '') +
			'&vsi=' +
			window.fedops.vsi +
			'&caching=' +
			caching +
			(bfcache ? ',browser_cache' : '') +
			'&pv=' +
			pageVisibilty +
			'&pn=1' +
			'&v=' +
			thunderboltVersion +
			'&url=' +
			encodeURIComponent(requestUrl) +
			'&st=' +
			st +
			`&ts=${ts}&tsn=${tsn}` +
			extra
		sendBeacon(url)
	}

	function sendPanorama({
		transaction,
		error,
	}: {
		transaction?: {
			name: string
		}
		error?: {
			name: string
			message: string
			stack: string
		}
	}) {
		const payload = [
			{
				fullArtifactId: 'com.wixpress.html-client.wix-thunderbolt',
				componentId: `${
					window.fedops.data.site.editorName === 'Studio'
						? 'wix-studio'
						: `thunderbolt${window.fedops.data.site.isResponsive ? '-responsive' : ''}`
				}`,
				platform: 'viewer',
				msid: window.fedops.data.site.metaSiteId,
				sessionId: window.fedops.vsi,
				sessionTime: Date.now() - window.initialTimestamps.initialTimestamp,
				logLevel: error ? 'ERROR' : 'INFO',
				message: error?.message ?? (transaction?.name && `${transaction.name} START`),
				errorName: error?.name,
				errorStack: error?.stack,
				transactionName: transaction?.name,
				transactionAction: transaction && 'START',
				isSsr: false,
				dataCenter: site.dc,
				isCached: !!isCached,
				isRollout: !!is_rollout,
				isHeadless: !!ish,
				isDacRollout: !!is_dac_rollout,
				isSavRollout: !!is_sav_rollout,
				isCompanyNetwork: !!is_company_network,
			},
		]
		try {
			const body = JSON.stringify({ messages: payload })
			return navigator.sendBeacon('https://panorama.wixapps.net/api/v1/bulklog', body)
		} catch (e) {
			console.error(e)
		}
	}

	function reportPhase(evid: number) {
		return (phase: string, extra?: { paramsOverrides?: { [key: string]: string } }) => {
			const duration = Date.now() - ts
			const baseParams = `&name=${phase}&duration=${duration}`
			const extraParams =
				extra && extra.paramsOverrides
					? Object.keys(extra.paramsOverrides)
							// @ts-ignore ts does not recognize the if before
							.map((key) => key + '=' + extra.paramsOverrides[key])
							.join('&')
					: ''
			sendBI(evid, extraParams ? `${baseParams}&${extraParams}` : baseParams)
		}
	}

	/* We don't want to send BI in deprecation flow */
	if (window.__browser_deprecation__) {
		return
	}

	const documentReferrerParam = document.referrer ? `&document_referrer=${document.referrer}` : ''
	const isMpa = window.sessionStorage.getItem('isMpa')
	const isMpaParam = isMpa ? `&isMpa=${isMpa}` : ''
	if (isMpa) {
		window.sessionStorage.removeItem('isMpa')
	}

	let mpaSessionId = window.sessionStorage.getItem('mpaSessionId')
	if (!mpaSessionId) {
		mpaSessionId = uuidv4()
		window.sessionStorage.setItem('mpaSessionId', mpaSessionId)
	}

	sendBI(
		21,
		`&platformOnSite=${window.fedops.data.platformOnSite}&mpaSessionId=${mpaSessionId}${documentReferrerParam}${isMpaParam}`
	)

	sendPanorama({
		transaction: {
			name: 'PANORAMA_COMPONENT_LOAD',
		},
	})
})()
